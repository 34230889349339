import axios from 'axios'
import React, {Ref, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Column, useTable, useResizeColumns, useFlexLayout} from 'react-table'
import {KTSVG} from '../../../_metronic/helpers'
import {checkValueExists} from '../components/CheckValueExists'
import {Styles} from '../components/Table_Style'
import {ManualMatching} from '../depositManagement/components/manual_matching'
import ResubmitCallback from './components/ResubmitCallback'
import {Filter_Pending} from './components/Filter_Pending'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../components/Popover'
import Deposit_Export_CSV from './components/Deposit_Export_CSV'
import {metricsArray} from '../components/MetricsArray'
import FormatNumber from '../components/FormatNumber'
import scrollToTop from '../components/ScrollToTop'
import {usePendingDepositStore} from './components/PendingDeposit_Store'
import {pushAllRow} from './components/PushAllRow'
import {pushUniqueRow} from './components/PushUniqueRow'
import DepositManagement_BulkApproval from './components/DepositManagement_BulkApproval'
import {MatchSMSModal_Nagad} from './components/MatchSMSModal_nagad'
import {MatchSMSModal_Bkash} from './components/MatchSMSModal_bkash'

const DepositManagementPage_Pending_List: React.FC = () => {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //States
  const [formData, setFormData] = useState([])
  const [depositData, setDepositData] = useState(Object)
  const [showMetrics, setShowMetrics] = useState(false)
  const [test, setTest] = useState(Boolean)
  const [selectedArray, setSelectedArray] = useState([])
  const [currentRejectDeposit, setCurrentRejectDeposit] = useState('')
  const [isAction, setIsAction] = useState(false)
  const [metrics, setMatrics] = useState<any>(undefined)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalFormData, setTotalFormData] = useState(0)
  const [filterValues, setFilterValues] = useState<any>([])
  const [downloadLoading, setDownloadLoading] = useState(false)

  //Refs
  const inputRef = useRef<any>()
  const depositFilterBtn = useRef<any>()

  //Zustand Store
  const setGetData = usePendingDepositStore((state: any) => state.setGetData)

  //Reject Deposit
  function rejectDeposit() {
    if (currentRejectDeposit !== '') {
      axios
        .post(
          `${SISPAY_API}/cash/depositrequest/reject`,
          {
            request: {
              id: currentRejectDeposit,
              //approvalRejectionRemark: remark,
              approvalRejectionRemark: inputRef.current.value,
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          depositFilterBtn.current.click()
          if (response.data.code == '200') {
            setGetData(true)
            alert('Deposit Rejected!')
          } else {
            setGetData(true)
            alert(response.data.message)
          }
        })
        .catch(function (error) {})
    } else {
      alert('An error happened.')
    }

    setCurrentRejectDeposit('')
    inputRef.current.value = ''
  }

  useEffect(() => {
    let metrics = localStorage.getItem('depositPendingMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  //Table::START
  interface Data {
    id: string
    depositDT: string
    merchantName: string
    merchantCd: string
    customerCd: string
    walletAccName: string
    walletCd: string
    amount: string
    depositStatus: string
    callbackStatus: string
    depositFee: string
    adjustmentRemark: string
    approvalRejectionRemark: string
    submittedTxnId: string
    walletAccNo: string
    agent: string
    merchantTxnId: string
    srcWalletAcc: string
    reference: string
    matchStatus: string
    completedDT: string
    successBy: string
    depositStatusUpdateDT: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        id: 'selection',
        Header: () => {
          return (
            //Check All Checkbox
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                title={'Select All ' + formData.length + ' Deposits'}
                style={{
                  cursor: 'pointer',
                }}
                type='checkbox'
                value=''
                id='checkAll'
                onChange={() => {
                  pushAllRow(selectedArray, formData, setSelectedArray)
                  setTest(!test)
                }}
                checked={selectedArray.length > 0 && selectedArray.length === formData.length}
              />
            </div>
          )
        },
        width: 50,
        accessor: (formData: any) => {
          return (
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                style={{
                  cursor: 'pointer',
                }}
                type='checkbox'
                value=''
                id={'checkbox_' + formData.walletAccNo}
                onChange={() => {
                  pushUniqueRow(selectedArray, formData, setSelectedArray)
                  setTest(!test)
                }}
                checked={checkValueExists(selectedArray, formData.id)}
              />
            </div>
          )
        },
      },
      {
        id: 'ID',
        Header: 'ID',
        accessor: 'id',
        width: 100,
      },
      {
        id: 'Deposit DateTime',
        Header: 'Deposit DateTime',
        accessor: (row: any) => {
          return row.depositDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        id: 'Completed DateTime',
        Header: 'Completed DateTime',
        accessor: (row: any) => {
          return row.completedDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        id: 'Status Updated DateTime',
        Header: 'Status Updated DT',
        accessor: (row: any) => {
          return row.depositStatusUpdateDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        id: 'Merchant',
        Header: 'Merchant',
        accessor: (row: any) => {
          return row.merchantName + ' - ' + row.merchantCd
        },
      },
      {
        id: 'Customer Code',
        Header: 'Customer Code',
        accessor: 'customerCd',
      },
      {
        id: 'Wallet Account Name',
        Header: 'Wallet Account Name',
        accessor: 'walletAccName',
      },
      {
        id: 'Wallet Account Code',
        Header: 'Wallet Account Code',
        accessor: (row: any) => {
          return row.walletCd.toUpperCase()
        },
      },
      {
        id: 'Amount',
        Header: 'Amount',
        accessor: (row: any) => {
          return FormatNumber(row.amount)
        },
      },
      {
        id: 'Deposit Status',
        Header: 'Deposit Status',
        accessor: 'depositStatus',
      },
      {
        id: 'Callback Status',
        Header: 'Callback Status',
        accessor: 'callbackStatus',
      },
      {
        id: 'Deposit Fee',
        Header: 'Deposit Fee',
        accessor: (row: any) => {
          return FormatNumber(row.depositFee)
        },
      },
      {
        id: 'Adjustment Remark',
        Header: 'Adjustment Remark',
        accessor: 'adjustmentRemark',
      },
      {
        id: 'Approval/ Rejection Remark',
        Header: 'Approval/ Rejection Remark',
        accessor: 'approvalRejectionRemark',
      },
      {
        id: 'Submitted Transaction ID',
        Header: 'Submitted Transaction ID',
        accessor: 'submittedTxnId',
      },
      {
        id: 'Wallet Account No',
        Header: 'Wallet Account No',
        accessor: 'walletAccNo',
      },
      {
        id: 'Agent',
        Header: 'Agent',
        accessor: 'agent',
      },
      {
        id: 'Merchant Transaction ID',
        Header: 'Merchant Transaction ID',
        accessor: 'merchantTxnId',
      },
      {
        id: 'Source Wallet Acc',
        Header: 'Source Wallet Acc',
        accessor: 'srcWalletAcc',
      },
      {
        id: 'Reference',
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        id: 'Match Status',
        Header: 'Match Status',
        accessor: 'matchStatus',
      },
      {
        id: 'Success By',
        Header: 'Success By',
        accessor: 'successBy',
      },
      {
        Header: 'Action',
        accessor: (formData: any) => {
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <a
                className='btn btn-icon btn-bg-danger btn-active-color-danger btn-sm me-2'
                data-bs-toggle='modal'
                href='#exampleModalToggle'
                role='button'
                onClick={() => {
                  setDepositData(formData)
                }}
                style={{
                  opacity:
                    formData.depositStatus === 'PENDING' ||
                    formData.depositStatus === 'PENDING-RECHECK'
                      ? '1'
                      : '0',
                  visibility:
                    formData.depositStatus === 'PENDING' ||
                    formData.depositStatus === 'PENDING-RECHECK'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <OverlayTrigger
                  trigger='hover'
                  placement='top'
                  overlay={popover('Manual Matching')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <i className='bi bi-lightning-charge-fill'></i>
                  </div>
                </OverlayTrigger>{' '}
              </a>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_rejectDeposit'
                onClick={() => {
                  setCurrentRejectDeposit(formData.id)
                }}
                style={{
                  opacity:
                    formData.depositStatus === 'PENDING' ||
                    formData.depositStatus === 'PENDING-RECHECK'
                      ? '1'
                      : '0',
                  visibility:
                    formData.depositStatus === 'PENDING' ||
                    formData.depositStatus === 'PENDING-RECHECK'
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <OverlayTrigger trigger='hover' placement='top' overlay={popover('Reject Deposit')}>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </button>
              <Link
                to={`/deposit/${formData.id}`}
                state={formData}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                onClick={() => setGetData(true)}
              >
                <OverlayTrigger trigger='hover' placement='top' overlay={popover('Check Details')}>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen038.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </Link>
            </div>
          )
        },
        width: 200,
      },
    ],
    [test, selectedArray]
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter(
                    (column) =>
                      column.Header !== ' ' &&
                      column.Header !== 'Action' &&
                      column.id !== 'selection'
                  )
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('depositPendingMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.id)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        <div className='d-flex'>
          <div className='fv-row me-5'>
            <button
              className='btn btn-info btn-sm fw-bold btn-color-white-700 w-100'
              disabled={formData.length > 0 && !downloadLoading ? false : true}
              onClick={() => {
                Deposit_Export_CSV(filterValues, setDownloadLoading)
              }}
            >
              {!downloadLoading ? 'Download Content as CSV' : 'Downloading...Please Wait!'}
            </button>
          </div>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_resubmit_callback'
            disabled={selectedArray.length > 0 ? false : true}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            BULK RESUBMIT CALLBACK
          </button>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_deposit_bulk_approval'
            disabled={selectedArray.length > 0 ? false : true}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            BULK APPROVAL
          </button>
          {/* <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-dark me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_match_sms_nagad'
            // disabled={selectedArray.length > 0 ? false : true}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            Match SMS (NAGAD)
          </button>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-dark me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_match_sms_bkash'
            // disabled={selectedArray.length > 0 ? false : true}
            onClick={() => {
              setIsAction(!isAction)
            }}
          >
            Match SMS (BKASH)
          </button> */}
        </div>
        <button
          type='button'
          className='btn btn-sm btn-flex fw-bold rotate'
          onClick={(e) => {
            setShowMetrics(!showMetrics)
            e.currentTarget.classList.toggle('active')
          }}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr004.svg'
            className='svg-icon-6 svg-icon-muted me-1 rotate-180'
          />{' '}
          Select Metrics
        </button>
      </div>

      <ResubmitCallback
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        setFormData={setFormData}
        depositFilterBtn={depositFilterBtn}
      />
      <DepositManagement_BulkApproval
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        setFormData={setFormData}
        depositFilterBtn={depositFilterBtn}
      />
      <Filter_Pending
        setFormData={setFormData}
        setTotalFormData={setTotalFormData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSelectedArray={setSelectedArray}
        depositFilterBtn={depositFilterBtn}
        setFilterValues={setFilterValues}
        downloadLoading={downloadLoading}
      />
      <MatchSMSModal_Nagad />
      <MatchSMSModal_Bkash />
      <Styles>
        <Table columns={columns} data={formData == undefined ? [] : formData} />
      </Styles>
      <ManualMatching depositData={depositData} depositFilterBtn={depositFilterBtn} />
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {totalFormData}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1)
            scrollToTop()
          }}
        >
          Previous
        </button>
        {currentPage == 0 ? (
          <div>1 of {Math.ceil(totalFormData / 200)}</div>
        ) : (
          <div>
            {currentPage} of {Math.ceil(totalFormData / 200)}
          </div>
        )}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage >= Math.ceil(totalFormData / 200)}
          onClick={() => {
            if (currentPage == 0) {
              setCurrentPage(2)
            } else {
              setCurrentPage(currentPage + 1)
            }
            scrollToTop()
          }}
        >
          Next
        </button>
        <select
          onChange={(e) => setCurrentPage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: Math.ceil(totalFormData / 200)}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
      </div>
      {/* Pagination END */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_rejectDeposit'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>Reject Deposit ID: {currentRejectDeposit}</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  setCurrentRejectDeposit('')
                  inputRef.current.value = ''
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <h5>Remark:</h5>
              <textarea className='w-100 h-200px' ref={inputRef}></textarea>
            </div>
            <div className='modal-footer'>
              <button
                type='submit'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  rejectDeposit()
                }}
              >
                Reject Deposit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepositManagementPage_Pending_List
