import React, {Ref, useState} from 'react'
import {Column, useFlexLayout, useResizeColumns, useTable} from 'react-table'

import {KTSVG} from '../../../../../_metronic/helpers'
import {Styles_NoAction} from '../../../components/Table_Style_NoAction'
import {Detailed_Filter} from './components/Filter'
import scrollToTop from '../../../components/ScrollToTop'
import DetailedReporting_Export_CSV from '../../../Reporting/Detailed Reporting/components/DetailedReporting_Export_CSV'
import DetailedReporting_Export_Excel from '../../../Reporting/Detailed Reporting/components/DetailedReporting_Export_Excel'
import DetailedReporting_Export_PDF from '../../../Reporting/Detailed Reporting/components/Export_PDF_Detailed_Report'
import Modal from '../../../components/Modal'

const Merchant_Detailed_Reporting_List: React.FC = () => {
  //States
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [isAction, setIsAction] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalFormData, setTotalFormData] = useState(0)
  const [filterValues, setFilterValues] = useState({})
  const [loading, setLoading] = useState(false)

  //Table::START
  interface Data {
    destWalletName: string
    amount: string
    srcWalletName: string
    completedDT: string
    fee: string
    merchantTxnId: string
    receivedDT: string
    submittedTxnId: string
    destWalletAcc: string
    referenceId: string
    merchantName: string
    walletCd: string
    transType: string
    srcWalletAcc: string
    clientIP: string
    submittedDT: string
    customerCd: string
    merchantCd: string
    status: string
    matchStatus: string
    successBy: string
    matchedTxnId: string
    agentComm: string
    txnAgentCommFee: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'Created DateTime',
        accessor: (row) => {
          return row.receivedDT.replace('T', ' ').split('.')[0]
        },
      },
      {
        Header: 'Submitted DateTime',
        accessor: (row) => {
          function convertDateTimeFormat(dateTimeStr: string) {
            // Separate date and time parts
            const datePart = dateTimeStr.slice(0, 8)
            const timePart = dateTimeStr.slice(9)

            // Format the date part (YYYYMMDD -> YYYY-MM-DD)
            const formattedDate = `${datePart.slice(0, 4)}-${datePart.slice(4, 6)}-${datePart.slice(
              6
            )}`

            // Format the time part (HH:mm:ss -> HH:mm:ss)
            const formattedTime = `${timePart.slice(0, 2)}:${timePart.slice(3, 5)}:${timePart.slice(
              6
            )}`

            return `${formattedDate} ${formattedTime}`
          }
          return convertDateTimeFormat(row.submittedDT)
        },
      },
      {
        Header: 'Completed DateTime',
        accessor: (row) => {
          return row.completedDT.replace('T', ' ').split('.')[0]
        },
      },
      {
        Header: 'Merchant',
        accessor: (row: any) => {
          return row.merchantName + ' - ' + row.merchantCd
        },
      },
      {
        Header: 'Customer Code',
        accessor: 'customerCd',
      },
      {
        Header: 'Bank',
        accessor: 'walletCd',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Transaction Type',
        accessor: 'transType',
      },
      {
        Header: 'Transaction Status',
        accessor: 'status',
      },
      //   {
      //     Header: 'Transaction Success By',
      //     accessor: 'status',
      //   },
      {
        Header: 'Fee',
        accessor: 'fee',
      },
      {
        Header: 'Merchant Transaction ID',
        accessor: 'merchantTxnId',
      },
      {
        Header: 'Submitted Transaction ID',
        accessor: 'submittedTxnId',
      },
      {
        Header: 'Reference ID',
        accessor: 'referenceId',
      },
      {
        Header: 'Source Account Number',
        accessor: 'srcWalletAcc',
      },
      {
        Header: 'Source Account Name',
        accessor: 'srcWalletName',
      },
      {
        Header: 'Destination Account Number',
        accessor: 'destWalletAcc',
      },
      {
        Header: 'Destination Account Name',
        accessor: 'destWalletName',
      },
      {
        Header: 'Match Status',
        accessor: 'matchStatus',
      },
      {
        Header: 'Matched Txn ID',
        accessor: 'matchedTxnId',
      },
      {
        Header: 'Transaction Success By',
        accessor: 'successBy',
      },
      {
        Header: 'Agent Commission',
        accessor: 'agentComm',
      },
      {
        Header: 'Agent Commission Fee',
        accessor: 'txnAgentCommFee',
      },
    ],
    []
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter((column) => column.Header !== ' ' && column.Header !== 'Action')
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  const keyMap = {
    destWalletName: 'Destination Account Name',
    amount: 'Amount',
    srcWalletName: 'Source Account Name',
    completedDT: 'Completed DateTime',
    fee: 'Fee',
    merchantTxnId: 'Merchant Transaction ID',
    receivedDT: 'Received DateTime',
    submittedTxnId: 'Submitted Transaction ID',
    destWalletAcc: 'Destination Account Number',
    referenceId: 'Reference ID',
    merchantName: 'Merchant Name',
    walletCd: 'Bank',
    transType: 'Transaction Type',
    srcWalletAcc: 'Source Account Number',
    clientIP: 'Client IP',
    submittedDT: 'Submitted DateTime',
    customerCd: 'Customer Code',
    merchantCd: 'Merchant Code',
    status: 'Transaction Status',
    matchStatus: 'Match Status',
    matchedTxnId: 'Matched Txn ID',
    successBy: 'Transaction Success By',
    // Add more key mappings here
  }

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0 d-flex'>
          {/* <button className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-primary ms-3 me-5'>
            Add Wallet
          </button>{' '} */}
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-warning w-100'
              onClick={() => {
                setIsAction(!isAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {isAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    // Export_CSV(formData, keyMap, startDate, endDate, 'Detailed_Report')
                    DetailedReporting_Export_CSV(filterValues, setLoading)
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    // Export_Excel(formData, keyMap, startDate, endDate, 'Detailed_Report')
                    DetailedReporting_Export_Excel(filterValues, setLoading)
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    // Export_PDF_Detailed_Report(formData, startDate, endDate)
                    DetailedReporting_Export_PDF(filterValues, setLoading)
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
        </div>

        <div className='m-0 d-flex'>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Detailed_Filter
        setFormData={setFormData}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalFormData={setTotalFormData}
        setFilterValues={setFilterValues}
      />
      <Styles_NoAction>
        <Table columns={columns} data={formData == undefined ? [] : formData} />
      </Styles_NoAction>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {totalFormData}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1)
            scrollToTop()
          }}
        >
          Previous
        </button>
        {currentPage == 0 ? (
          <div>1 of {Math.ceil(totalFormData / 100)}</div>
        ) : (
          <div>
            {currentPage} of {Math.ceil(totalFormData / 100)}
          </div>
        )}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage >= Math.ceil(totalFormData / 100)}
          onClick={() => {
            if (currentPage == 0) {
              setCurrentPage(2)
            } else {
              setCurrentPage(currentPage + 1)
            }
            scrollToTop()
          }}
        >
          Next
        </button>
        <select
          onChange={(e) => setCurrentPage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: Math.ceil(totalFormData / 100)}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
      </div>
      {/* Pagination END */}
      {Modal(loading)}
    </div>
  )
}

export default Merchant_Detailed_Reporting_List
